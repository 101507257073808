import React, { useState } from "react"
import { Button, Divider, Dropdown, Radio, Space, Typography, theme } from "antd"
import { CloseOutlined, DownOutlined } from "@ant-design/icons"
import { ONBOARDING_STAGES } from "./constants"

const { useToken } = theme

const OnBoardingStageDropdown = props => {
  const { onboardingSelectedStage, setOnboardingSelectedStage, disabled } = props
  const { token } = useToken()

  const [open, setOpen] = useState(false)
  const [values, setValues] = useState()

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary
    // width: "140%"
  }

  const handleApply = () => {
    setOpen(false)
    setOnboardingSelectedStage(values)
  }

  const handleResetFilter = () => {
    setValues("")
    setOnboardingSelectedStage("")
  }

  const onOpenChange = open => {
    if (!open) setValues(onboardingSelectedStage)
    setOpen(open)
  }

  const onBoardingStageOptionChange = e => {
    setValues(e.target.value)
  }
  return (
    <Space.Compact onClick={e => e.stopPropagation()}>
      <Dropdown
        open={open}
        disabled={disabled}
        onOpenChange={onOpenChange}
        destroyPopupOnHide
        menu={{ items: [] }}
        trigger={["click"]}
        className="flex-1 bg-[#fff]"
        dropdownRender={_ => (
          <div style={contentStyle}>
            <div className="pt-2 px-4 mr-8">
              <Radio.Group onChange={onBoardingStageOptionChange} value={values}>
                <Space direction="vertical">
                  {Object.values(ONBOARDING_STAGES ?? {})?.map(val => (
                    <Radio key={val} value={val}>
                      {val}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </div>
            <Divider className="my-3" />

            <div className="px-8 pb-4 flex justify-center">
              <Button type="primary" onClick={handleApply}>
                Apply Now
              </Button>
            </div>
          </div>
        )}>
        <Button
          style={{
            // minWidth: 150,
            height: 48
          }}>
          <div className="flex items-center justify-center gap-2">
            {(onboardingSelectedStage && (
              <>
                <Typography.Link>Onboarding Stage : {onboardingSelectedStage}</Typography.Link>
                <Typography.Link>
                  <DownOutlined />
                </Typography.Link>
              </>
            )) || (
              <>
                Onboarding Stage
                <DownOutlined />
              </>
            )}
          </div>
        </Button>
      </Dropdown>
      <Button disabled={disabled} onClick={handleResetFilter} className="h-full px-4 bg-[#fff]">
        <CloseOutlined />
      </Button>
    </Space.Compact>
  )
}

export default OnBoardingStageDropdown
