import { UserOutlined } from "@ant-design/icons"
import { BsBuilding } from "react-icons/bs"

const Navigation = [
  {
    key: "/",
    label: "Organization",
    icon: <BsBuilding />
  },
  {
    key: "/patient",
    label: "Patient",
    icon: <UserOutlined />
  }
]

export default Navigation
