import React, { useState } from "react"
import { NavLink, useNavigate, useLocation, useParams } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"

import { Input, Layout, Button, Space, Grid, Avatar, AutoComplete, Dropdown } from "antd"

import { MenuFoldOutlined, MenuOutlined } from "@ant-design/icons"
// import { darkModeAction } from "../redux/actions/themeActions"
import { prevUserLogoutAction } from "../redux/actions/userActions"

import Navigation from "./Navigation"
import { Mixpanel } from "../utils/mixpanel"
import ProfileAvatar from "../utils/ProfileAvatar"

const { Search } = Input
const { Header } = Layout
const { useBreakpoint } = Grid

const generateOptions = arr => {
  const result = []
  arr.map(i => {
    if (i.children) {
      generateOptions(i.children)
    } else {
      result.push({
        value: i.key,
        label: i.label
      })
    }
    return i
  })
  return result
}

const Navbar = ({
  sidebarHidden,
  setSidebarHidden,
  setSidebarCollapsed,
  sidebarCollapsed,
  from
}) => {
  const [searchVal, setSearchVal] = useState("")
  const { orgId, orgName } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const screens = useBreakpoint()
  const isMobile = !screens?.lg

  const items = [
    {
      key: "2",
      label: (
        <NavLink
          className="no-underline"
          to={`/${orgId}/${orgName}/patient`}
          onClick={() => Mixpanel.track("Patients Tab Clicked")}>
          Patients
        </NavLink>
      )
    },
    {
      key: "3",
      label: (
        <NavLink
          className="no-underline"
          to="/settings"
          onClick={() => Mixpanel.track("Settings Clicked")}>
          Settings
        </NavLink>
      )
    }
  ]
  const dispatch = useDispatch()
  const { userInfo } = useSelector(state => state.userInfo)

  const handleLogout = e => {
    e.preventDefault()
    Mixpanel.track("Logout Clicked")
    Mixpanel.reset()
    dispatch(prevUserLogoutAction())
  }

  const handleOptionSelect = val => {
    if (location.pathname !== val) {
      navigate(val)
      setSearchVal("")
    }
  }

  const handleToggleSidebar = () => {
    if (isMobile) {
      setSidebarHidden(false)
      setSidebarCollapsed(false)
    } else {
      setSidebarCollapsed(!sidebarCollapsed)
    }
  }

  return (
    <Header className="sticky-top shadow-sm" style={{ zIndex: 2 }}>
      <Space className="navbar-left">
        {(isMobile && sidebarHidden) || (!isMobile && !sidebarCollapsed) ? (
          <Button
            shape="circle"
            icon={<MenuFoldOutlined />}
            className="sidebar-trigger mr-3"
            onClick={handleToggleSidebar}
          />
        ) : null}
        {from !== "org" ? (
          <AutoComplete
            value={searchVal}
            className="mb-3 hidden sm:inline-block"
            options={generateOptions(Navigation)}
            onSelect={handleOptionSelect}>
            <Search placeholder="Search..." />
          </AutoComplete>
        ) : null}
      </Space>
      <div className="navbar-right flex items-center gap-3">
        {userInfo && (
          <Dropdown
            trigger={["click"]}
            menu={{
              items: [
                {
                  key: "1",
                  label: (
                    <h6 className="capitalize mb-0 pb-2 text-lg font-medium border-bottom border-current">
                      {userInfo ? `${userInfo?.firstName} ${userInfo?.lastName}` : "John Doe"}
                    </h6>
                  )
                },
                ...(from === "org" ? [] : items), // no need to show the other option when we are at org list
                {
                  key: "4",
                  label: (
                    <a href="#" className="no-underline" onClick={handleLogout}>
                      Logout
                    </a>
                  )
                }
              ]
            }}>
            <Space className="dropdown-trigger" align="center">
              <ProfileAvatar size={40} user={userInfo} /> 
              <span className="flex flex-col justify-center items-start">
                <h6 className="capitalize text-lg hidden sm:block">
                  {userInfo ? `${userInfo?.firstName}` : ""}
                </h6>
                <span className="h-4 text-sm capitalize hidden sm:flex sm:justify-center sm:items-start">
                  {userInfo ? (orgName ? `${orgName}` : userInfo?.orgName) : ""}
                </span>
              </span>
            </Space>
          </Dropdown>
        )}
      </div>
    </Header>
  )
}

export default Navbar
