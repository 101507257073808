import AuthGuard from "../components/AuthGuard"
import ForgetPasswordView from "../views/ForgetPasswordView"
import LoginView from "../views/LoginView"
import PatientListView from "../views/PatientListView"
import MainLayout from "../layout/MainLayout"
import OrganizationList from "../components/Organization"
import Page404 from "../views/404"
import AccountView from "../views/AccountView"

const Routes = [
  {
    path: "/",
    element: (
      <AuthGuard>
        <MainLayout from="org">
          <OrganizationList />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/:orgId/:orgName/patient",
    element: (
      <AuthGuard>
        <MainLayout>
          <PatientListView />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "/login",
    element: <LoginView />
  },
  {
    path: "/forgot-password",
    element: <ForgetPasswordView />
  },
  {
    path: "/settings",
    element: (
      <AuthGuard>
        <MainLayout from="org">
          <AccountView />
        </MainLayout>
      </AuthGuard>
    )
  },
  {
    path: "*",
    element: <Page404 />
  }
]
export default Routes
