import React from "react"

import "./FloatInput.css"

const FloatInput = ({ children, label, placeholder }) => {
  const childrenProps = children?.props

  if (!placeholder) placeholder = label

  const isOccupied = childrenProps?.value && childrenProps?.value.length !== 0

  const labelClass = isOccupied ? "label as-label" : "label as-placeholder"

  const requiredMark = childrenProps?.required ? <span className="text-danger">*</span> : null

  return (
    <div className="float-label">
      {children}
      <label className={labelClass}>
        {isOccupied ? label : placeholder} {requiredMark}
      </label>
    </div>
  )
}

export default FloatInput
