export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"
export const USER_LOGIN_RESET = "USER_LOGIN_RESET"

export const GOOGLE_LOGIN_REQUEST = "GOOGLE_LOGIN_REQUEST"
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS"
export const GOOGLE_LOGIN_FAIL = "GOOGLE_LOGIN_FAIL"

export const FORGET_PASSWORD_OPT_REQUEST = "FORGET_PASSWORD_OPT_REQUEST"
export const FORGET_PASSWORD_OPT_SUCCESS = "FORGET_PASSWORD_OPT_SUCCESS"
export const FORGET_PASSWORD_OPT_FAIL = "FORGET_PASSWORD_OPT_FAIL"

export const PASSWORD_UPDATE_REQUEST = "PASSWORD_UPDATE_REQUEST"
export const PASSWORD_UPDATE_SUCCESS = "PASSWORD_UPDATE_SUCCESS"
export const PASSWORD_UPDATE_FAIL = "PASSWORD_UPDATE_FAIL"

export const USER_LOGOUT = "USER_LOGOUT"

export const VALIDATE_LOGIN_DETAILS_REQUEST = "VALIDATE_LOGIN_DETAILS_REQUEST"
export const VALIDATE_LOGIN_DETAILS_SUCCESS = "VALIDATE_LOGIN_DETAILS_SUCCESS"
export const VALIDATE_LOGIN_DETAILS_FAIL = "VALIDATE_LOGIN_DETAILS_FAIL"

export const UPDATE_PROFILE_DETAILS_REQUEST = "UPDATE_PROFILE_DETAILS_REQUEST"
export const UPDATE_PROFILE_DETAILS_SUCCESS = "UPDATE_PROFILE_DETAILS_SUCCESS"
export const UPDATE_PROFILE_DETAILS_FAIL = "UPDATE_PROFILE_DETAILS_FAIL"

export const POST_FEEDBACK_REQUEST = "POST_FEEDBACK_REQUEST"
export const POST_FEEDBACK_SUCCESS = "POST_FEEDBACK_SUCCESS"
export const POST_FEEDBACK_FAIL = "POST_FEEDBACK_FAIL"
export const POST_FEEDBACK_RESET = "POST_FEEDBACK_RESET"
