import React from "react"
import { useNavigate, NavLink, useLocation, useParams } from "react-router-dom"

import { Layout, Menu, Button, Grid } from "antd"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"

import Navigation from "./Navigation"
import Logo from "../assets/fitpeo_logo.png"
import { Mixpanel } from "../utils/mixpanel";

const { Sider } = Layout
const { useBreakpoint } = Grid

const Sidebar = props => {
  const { sidebarHidden, sidebarCollapsed, setSidebarCollapsed, setSidebarHidden } = props

  const navigate = useNavigate()
  const location = useLocation()
  const screens = useBreakpoint()
  const isMobile = !screens?.lg
  const {orgId, orgName} = useParams()

  const actionIcon = () => {
    if (isMobile) {
      return <LeftOutlined />
    }
    return <RightOutlined />
  }

  const handleActionIconClick = () => {
    if (isMobile) {
      setSidebarHidden(true)
    } else {
      setSidebarCollapsed(!sidebarCollapsed)
    }
  }

  const activeURL = () => {
    let indicesToRemove = [1, 2]; // as orgId and orgName not needed so trimming them off
    const splitURL = location.pathname.split("/").filter((item, index) => !indicesToRemove.includes(index))
    if (splitURL.length > 2) {
      return `/${splitURL.filter(u => u.length).join("/")}`
    }
    return `/${splitURL[1]}`
  }

  return (
    <Sider
      theme="light"
      breakpoint="lg"
      trigger={null}
      hidden={sidebarHidden}
      collapsible={!isMobile}
      collapsed={sidebarCollapsed}
      collapsedWidth={!isMobile ? "60px" : 0}
      className={sidebarHidden ? "closed" : "open"}
      onCollapse={value => {
        setSidebarCollapsed(value)
      }}>
      <div className="sidebar-header">
        {sidebarCollapsed ? null : (
          <NavLink to="/">
            <img className="logo img-fluid" src={Logo} alt="logo" />
          </NavLink>
        )}
        {(!isMobile && sidebarCollapsed) || (isMobile && !sidebarHidden) ? (
          <Button
            shape="circle"
            icon={actionIcon()}
            className="sidebar-toggler"
            onClick={handleActionIconClick}
          />
        ) : null}
      </div>
      <Menu
        mode="inline"
        items={Navigation}
        selectedKeys={activeURL()}
        triggerSubMenuAction="click"
        onClick={({ key }) => {
          let page = key.split('/')?.[1] ? key.split('/')?.[1] : "Home"
          Mixpanel.track(`${page[0].toUpperCase() + page.slice(1)} Tab Clicked`)
          navigate(key === "/" ? key :`/${orgId}/${orgName}${key}`) // for org only we dont need org id
          if (isMobile) setSidebarHidden(true)
        }}
      />
    </Sider>
  )
}

export default Sidebar
