import React, { useEffect, useState } from "react"

import { Button, Typography } from "antd"
import { InputOTP } from "antd-input-otp"
import { useToasts } from "react-toast-notifications"

import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import "./forgetPassword.css"
import { USER_LOGIN_RESET } from "../../redux/types/userConstant"
import { Mixpanel } from "../../utils/mixpanel"
import { forgetPasswordAction, validateOtpAction } from "../../redux/actions/userActions.js"

const CODE_VALIDATION_MSG = "Code must be of 6 characters"
const CODE_SENT_SUCCESS_MSG = "Code Sent Successfully!"
const CODE_SENT_FAIL_MSG = "Oops! Unable to send Code, Try later"
const RESENT_CODE = "Resend Code"

const OTPForm = ({ credential, setShowNext, setOtpValue, setChangePasswordShow }) => {
  const [otp, setOtp] = useState("")
  const [errors, setErrors] = useState("")
  const [codeErrors, setCodeErrors] = useState("")
  const [loading, setLoading] = useState(false)
  const [resendOtpLimiter, setResendOtpLimiter] = useState(30)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const handleSubmit = async () => {
    setErrors("")
    Mixpanel.track("Validate OTP")
    if (!otp.length || otp.some(o => o === "") || otp.length < 6) {
      setErrors(CODE_VALIDATION_MSG)
    } else {
      setErrors("")

      setLoading(true)
      const result = await dispatch(validateOtpAction(credential, otp.join("")))
      if (result?.message === "Success" && result?.details?.setPassword === true) {
        setShowNext(false)
        setChangePasswordShow(true)
        setOtpValue(otp.join(""))
        addToast("Please Change Your Password", {
          appearance: "success",
          autoDismiss: true
        })
      } else {
        setCodeErrors(result)
        setOtp("")
        // addToast(CODE_SENT_FAIL_MSG, {
        //   appearance: "error",
        //   autoDismiss: true
        // })
      }
      setLoading(false)
    }
  }

  const resendOtp = async () => {
    if (resendOtpLimiter > 0) {
      addToast(`Please wait for ${resendOtpLimiter} seconds`, {
        appearance: "warning",
        autoDismiss: true
      })
      return
    }

    setResendOtpLimiter(30)
    const result = await dispatch(forgetPasswordAction(credential))
    if (result?.status === 200) {
      setOtp("")
      addToast(CODE_SENT_SUCCESS_MSG, {
        appearance: "success",
        autoDismiss: true
      })
    } else {
      setCodeErrors(result)
      addToast(CODE_SENT_FAIL_MSG, {
        appearance: "error",
        autoDismiss: true
      })
    }
  }

  useEffect(() => {
    if (resendOtpLimiter > 0) {
      const intervalId = setInterval(() => setResendOtpLimiter(resendOtpLimiter - 1), 1000)
      return () => clearInterval(intervalId)
    }
  }, [resendOtpLimiter])

  const DISPLAY_TITLE = credential ? `Enter code sent to ${credential}` : "Enter code sent to your Email / Mobile"

  return (
    <>
      <div className="mb-3">
        {!loading &&
          codeErrors?.message && codeErrors?.message !== "" && (
            <div
              style={{
                backgroundColor: "rgba(255, 59, 48, 0.06)",
                borderLeft: "2px solid rgba(255, 59, 48, .6)"
              }}
              className="px-3 py-2 my-4">
              <p>
                <strong>Note: </strong>
                {codeErrors?.message}
              </p>
            </div>
          )}
        <p className="text-center my-3">{DISPLAY_TITLE}</p>

        <InputOTP
          onChange={setOtp}
          value={otp}
          inputType="numeric"
          length={6}
          inputClassName="h-[40px] text-lg"
          status={
            errors || (!loading && codeErrors?.message && codeErrors?.message !== "" && !otp)
              ? "error"
              : undefined
          }
        />
        <div className="mt-1.5 flex items-center">
          <span className="flex flex-1">
            {!loading && codeErrors?.message && codeErrors?.message !== "" && !otp && (
              <small className="text-danger">Wrong Code</small>
            )}
          </span>
          <small
            className={`cursor-pointer ${resendOtpLimiter > 0 ? "text-secondary" : "text-current"}`}
            onClick={() => {
              if (!codeErrors?.isBlocked) {
                resendOtp()
              }
            }}>
            {RESENT_CODE} {resendOtpLimiter > 0 && `(${resendOtpLimiter})`}
          </small>
        </div>
      </div>
      <Button
        block
        size="large"
        type="primary"
        onClick={handleSubmit}
        disabled={
          loading ||
          !otp.length ||
          otp.some(o => o === "" || o === undefined) ||
          otp?.join("").length < 6 ||
          codeErrors?.isBlocked
        }>
        Submit
      </Button>
      <div className="mt-4">
        <small
          className="text-center cursor-pointer text-secondary"
          onClick={() => {
            setShowNext(false)
            dispatch({ type: USER_LOGIN_RESET })
            navigate('/login')
          }}>
          Back to Login
        </small>
      </div>

      {errors && typeof errors === "string" ? (
        <p className="text-danger block text-center font-medium mt-2">{errors}</p>
      ) : null}

      {errors &&
        typeof errors === "object" &&
        Object.values(errors)?.map((value, index) => (
          <p key={index} className="font-bold text-danger text-center mt-4">
            <small>{value}</small>
          </p>
        ))}
    </>
  )
}

export default OTPForm
