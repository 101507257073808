import {
  GET_INTERNAL_NOTE_REQUEST,
  GET_INTERNAL_NOTE_SUCCESS,
  GET_INTERNAL_NOTE_FAIL,
  ADD_INTERNAL_NOTE_REQUEST,
  ADD_INTERNAL_NOTE_SUCCESS,
  ADD_INTERNAL_NOTE_FAIL
} from "../types/internalNotesConstant"

// add note or add concern
export const addInternalNoteReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_INTERNAL_NOTE_REQUEST:
      return { loading: true }
    case ADD_INTERNAL_NOTE_SUCCESS:
      return { loading: false, addInternalNote: action.payload }
    case ADD_INTERNAL_NOTE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getInternalNotesReducer = (state = { loading: false, internalNotes: [] }, action) => {
  switch (action.type) {
    case GET_INTERNAL_NOTE_REQUEST:
      return { loading: true }
    case GET_INTERNAL_NOTE_SUCCESS:
      return { loading: false, internalNotes: action.payload }
    case GET_INTERNAL_NOTE_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
