import React, { useState, useEffect } from "react"

import { Card, Input, Row, Col, Button } from "antd"

import { useDispatch, useSelector } from "react-redux"

import { useToasts } from "react-toast-notifications"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { updateProfileDetailsAction, getUserProfileAction } from "../../redux/actions/userActions"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import { PatternFormat } from "react-number-format"
import packageJson from "../../../package.json"
import PersonalDetails from "./PersonalDetails"

const schema = yup
  .object({
    email: yup
      .string()
      .email()
      .matches(/^[A-Za-z0-9._]{3,}@[a-zA-Z]{3,}[.]{1,1}[a-zA-Z.]{2,6}$/g, "Invalid email")
      .required("Invalid Email"),
    mobileNumber: yup
      .string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Invalid Mobile Number")
      .required("Invalid Mobile Number")
  })
  .required()

const Profile = () => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { userInfo } = useSelector(state => state.userInfo)

  const { loading: updateProfileDetailsLoading, error } = useSelector(
    state => state.updateProfileDetails
  )

  const [isFocused, setIsFocused] = useState(false)

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (userInfo?.email) {
      setValue("email", userInfo?.email)
    }
    if (userInfo?.mobileNumber) {
      setValue("mobileNumber", formatPhoneNumber(userInfo?.mobileNumber))
    }
  }, [userInfo]) //eslint-disable-line

  const onSubmit = async data => {
    const result = await dispatch(
      updateProfileDetailsAction(userInfo?.id, {
        ...userInfo,
        ...data,
        mobileNumber: data?.mobileNumber?.replace(/[^\d]/g, ""),
        countryCode: "US",
        profileTypes: userInfo?.grantType
      })
    )
    if (result?.message?.toLowerCase() === "success") {
      addToast("Enrollment Specialist Updated Successfully", {
        appearance: "success",
        autoDismiss: true
      })
      setTimeout(
        () => dispatch(getUserProfileAction({ id: userInfo?.id, redirectToHome: false })),
        1000
      )
    } else {
      addToast("Error While Updating Enrollment Specialist", {
        appearance: "error",
        autoDismiss: true
      })
    }
  }

  const handleReset = () => {
    if (userInfo?.email) {
      setValue("email", userInfo?.email)
    }
    if (userInfo?.mobileNumber) {
      setValue("mobileNumber", formatPhoneNumber(userInfo?.mobileNumber))
    }
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const handleMobileChange = e => {
    const { value } = e.target
    return formatPhoneNumber(value)
  }
  return (
    <Row gutter={[16, 16]}>
    <Col sm={24} lg={8}>
      <PersonalDetails />
    </Col>
    <Col sm={24} lg={16}>
    <Card title="">
      <div className="flex justify-content-between align-items-center mb-4">
        <div>
          <h2 className="text-2xl">Contact Details</h2>
        </div>
      </div>

      <hr className="my-4" />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row gutter={16}>
          <Col sm={24} lg={24} className="mb-4 w-full">
            <label className="mb-2" htmlFor="input-email">
              Email <span className="text-danger">*</span>
            </label>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  status={errors.email ? "error" : undefined}
                  size="large"
                  id="input-email"
                  {...field}
                  onChange={e => field.onChange(e?.target?.value?.replace(/\s/g, ""))}
                />
              )}
            />
            {errors.email ? <small className="text-danger">{errors.email.message}</small> : null}
          </Col>
          <Col xs={24} lg={24} className="mb-4 w-full">
            <label className="mb-2" htmlFor="input-mobileNumber">
              Mobile Number <span className="text-danger">*</span>
            </label>
            <Controller
              name="mobileNumber"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const { ref, ...rest } = field
                return (
                  <PatternFormat
                    format="(###) ###-####"
                    className={`formatted-mobile-input flex p-2 text-[1rem] w-full  border border-${
                      errors.mobileNumber ? "danger" : isFocused ? "primary" : "#dfdfdf"
                    }  rounded-lg focus:outline-none`}
                    id="input-mobileNumber"
                    {...rest}
                    onChange={e => rest.onChange(handleMobileChange(e))}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                  />
                )
              }}
            />
            {errors.mobileNumber ? (
              <small className="text-danger">{errors.mobileNumber.message}</small>
            ) : null}
          </Col>

          {error && Object.keys(error).length ? (
            <Col xs={24} className="w-full mb-4">
              {Object.keys(error).map(err => (
                <p className="text-danger font-semibold">{error[err]}</p>
              ))}
            </Col>
          ) : null}
          <Col xs={24} className="w-full">
            <div className="flex flex-wrap gap-3 mt-3">
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                disabled={updateProfileDetailsLoading}>
                {updateProfileDetailsLoading ? "Submitting..." : "Submit"}
              </Button>
              <Button size="large" danger onClick={handleReset}>
                Reset
              </Button>
            </div>
          </Col>
        </Row>
      </form>
    </Card>
    </Col>
    </Row>
  )
}

export default Profile
