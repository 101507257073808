import React, { useState } from "react"
import { Avatar, Badge, Button, Card, Divider, Image, Input } from "antd"
import { isImage } from "../../../utils/isImage"
import { getFormat } from "../../../utils/getFileExtention"
import { useDispatch, useSelector } from "react-redux"
import { useToasts } from "react-toast-notifications"
import { CloseCircleFilled, FileOutlined, PlusOutlined } from "@ant-design/icons"
import { uploadFileAction } from "../../../redux/actions/uploadFileAction"
import { addInternalNoteAction } from "../../../redux/actions/internalNotesAction"

const { TextArea } = Input

const AddInternalNote = ({ patientId, closeModal }) => {
  const dispatch = useDispatch()
  const { addToast } = useToasts()

  const { loading: addNoteLoading } = useSelector(state => state.addInternalNote)
  const { loading: uploadLoading } = useSelector(state => state.uploadFiles)

  const [fileList, setFileList] = useState([])

  const [note, setNote] = useState({
    title: "",
    concern: "",
    files: []
  })

  const displayError = errorMsg => {
    addToast(errorMsg, {
      appearance: "error",
      autoDismiss: true
    })
  }

  const displaySuccess = successMsg => {
    addToast(successMsg, {
      appearance: "success",
      autoDismiss: true
    })
  }

  const handleInput = e => {
    const name = e?.target?.name
    const value = e?.target?.value
    setNote({ ...note, [name]: value })
  }

  const appendFile = e => {
    if (!e?.target?.files?.length) return

    const maxAllowedSize = 5 * 1024 * 1024
    const approved = [...e.target.files].filter(({ size }) => size <= maxAllowedSize)

    if (e.target.files.length !== approved.length)
      displayError("File size should be less than 5 mb!")

    setFileList([...fileList, ...approved])
  }

  const popFile = index => {
    const filteredFiles = [...fileList]
    filteredFiles.splice(index, 1)
    setFileList(filteredFiles)
  }

  const popFileUrl = index => {
    const filteredFiles = [...note?.files]
    filteredFiles.splice(index, 1)
    setNote({ ...note, files: filteredFiles })
  }

  const onSave = async () => {
    let uploadedFileUrls = []

    if (fileList?.length) {
      const { isUploaded, error, fileUrls } = await dispatch(
        uploadFileAction({ userId: patientId, fileList, fileType: "concerns" })
      )
      if (isUploaded) {
        setNote({ ...note, files: [...(note?.files || []), ...fileUrls] })
        setFileList([])
        uploadedFileUrls = fileUrls
      } else return displayError(error)
    }

    const { isDone, error } = await dispatch(
      addInternalNoteAction({
        patientId,
        title: note?.title,
        concern: note?.concern,
        files: [...(note?.files || []), ...uploadedFileUrls]
      })
    )
    if (isDone) {
      displaySuccess("Successfully Added!")
      setNote({})
      closeModal()
    } else displayError(error)
  }

  return (
    <form className="add-edit-note-form">
      <Card styles={{ body: { padding: 1 } }}>
        <div className="flex flex-col justify-between">
          <Input
            name="title"
            value={note?.title}
            onChange={handleInput}
            placeholder="Heading"
            className="px-1.5 font-semibold mb-1"
          />

          <TextArea
            name="concern"
            value={note?.concern}
            onChange={handleInput}
            placeholder="Add internal note..."
            className="px-1.5"
          />
          <div className="px-1">
            <Divider className="m-0" />
          </div>

          {(fileList?.length > 0 || note?.files?.length > 0) && (
            <div className="mx-1.5 mt-2.5 mb-1 flex flex-wrap gap-3">
              {note?.files?.map((file, idx) => (
                <Badge
                  key={idx}
                  count={
                    <CloseCircleFilled
                      onClick={() => popFileUrl(idx)}
                      style={{ color: "#7E7E7E" }}
                    />
                  }>
                  <Card styles={{ body: { padding: "4px 10px", width: "200px" } }}>
                    <div className="flex gap-2 items-center">
                      {isImage(file?.split("$")?.[1]) ? (
                        <Image width={32} src={file} />
                      ) : (
                        <Avatar shape="square" className="bg-current" icon={<FileOutlined />} />
                      )}
                      <div>
                        <p className="font-semibold mb-[-5px] truncate w-[140px]">
                          {file?.split("$")?.[1]}
                        </p>
                        <small className="uppercase text-secondary">
                          {getFormat(file?.split("$")?.[1])}
                        </small>
                      </div>
                    </div>
                  </Card>
                </Badge>
              ))}

              {fileList?.map((file, idx) => (
                <Badge
                  key={idx}
                  count={
                    <CloseCircleFilled onClick={() => popFile(idx)} style={{ color: "#7E7E7E" }} />
                  }>
                  <Card styles={{ body: { padding: "4px 10px", width: "200px" } }}>
                    <div className="flex gap-2 items-center">
                      {file?.type?.split("/")?.[0] === "image" ? (
                        <Image width={32} src={URL.createObjectURL(file)} />
                      ) : (
                        <Avatar shape="square" className="bg-current" icon={<FileOutlined />} />
                      )}

                      <div>
                        <p className="font-semibold mb-[-5px] truncate w-[140px]">{file?.name}</p>
                        <small className="uppercase text-secondary">{getFormat(file?.name)}</small>
                      </div>
                    </div>
                  </Card>
                </Badge>
              ))}
            </div>
          )}

          <div className="my-1">
            <Button type="link" className="p-1.5">
              <label
                htmlFor="upload-file"
                className="cursor-pointer flex justify-center gap-2 items-center">
                <Avatar size={17} className="bg-current">
                  <PlusOutlined />
                </Avatar>
                Add Attachments
              </label>
            </Button>
            <input
              type="file"
              id="upload-file"
              multiple
              onChange={appendFile}
              style={{ display: "none" }}
            />
          </div>
        </div>
      </Card>
      <div className="flex justify-end">
        <Button
          type="primary"
          size="large"
          className="w-[120px] mt-4 mb-1"
          onClick={onSave}
          disabled={!note?.title || !note?.concern}
          loading={uploadLoading || addNoteLoading}>
          Save
        </Button>
      </div>
    </form>
  )
}

export default AddInternalNote
