export const GET_PATIENT_LIST_REQUEST = "GET_PATIENT_LIST_REQUEST"
export const GET_PATIENT_LIST_SUCCESS = "GET_PATIENT_LIST_SUCCESS"
export const GET_PATIENT_LIST_FAIL = "GET_PATIENT_LIST_FAIL"

export const EDIT_PATIENT_REQUEST = "EDIT_PATIENT_REQUEST"
export const EDIT_PATIENT_SUCCESS = "EDIT_PATIENT_SUCCESS"
export const EDIT_PATIENT_FAIL = "EDIT_PATIENT_FAIL"
export const EDIT_PATIENT_RESET = "EDIT_PATIENT_RESET"

export const POST_REGISTER_OTP_REQUEST = "POST_REGISTER_OTP_REQUEST"
export const POST_REGISTER_OTP_SUCCESS = "POST_REGISTER_OTP_SUCCESS"
export const POST_REGISTER_OTP_FAIL = "POST_REGISTER_OTP_FAIL"
export const POST_REGISTER_OTP_RESET = "POST_REGISTER_OTP_RESET"

export const POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST = "POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST"
export const POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS = "POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS"
export const POST_BULK_MAIL_MESSAGE_PATIENT_FAIL = "POST_BULK_MAIL_MESSAGE_PATIENT_FAIL"

export const GET_MARK_PATIENT_ONBOARD_REQUEST = "GET_MARK_PATIENT_ONBOARD_REQUEST"
export const GET_MARK_PATIENT_ONBOARD_SUCCESS = "GET_MARK_PATIENT_ONBOARD_SUCCESS"
export const GET_MARK_PATIENT_ONBOARD_FAIL = "GET_MARK_PATIENT_ONBOARD_FAIL"
export const GET_MARK_PATIENT_ONBOARD_RESET = "GET_MARK_PATIENT_ONBOARD_RESET"

export const DENIED_ENROLLMENT_REQUEST = "DENIED_ENROLLMENT_REQUEST"
export const DENIED_ENROLLMENT_SUCCESS = "DENIED_ENROLLMENT_SUCCESS"
export const DENIED_ENROLLMENT_FAIL = "DENIED_ENROLLMENT_FAIL"
export const DENIED_ENROLLMENT_RESET = "DENIED_ENROLLMENT_RESET"

export const POST_PATIENT_MANUAL_ENROLL_REQUEST = "POST_PATIENT_MANUAL_ENROLL_REQUEST"
export const POST_PATIENT_MANUAL_ENROLL_SUCCESS = "POST_PATIENT_MANUAL_ENROLL_SUCCESS"
export const POST_PATIENT_MANUAL_ENROLL_FAIL = "POST_PATIENT_MANUAL_ENROLL_FAIL"
export const POST_PATIENT_MANUAL_ENROLL_RESET = "POST_PATIENT_MANUAL_ENROLL_RESET"