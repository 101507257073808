// checking for no netwrork connection

const NoNetworkHandler = () => {
  if (navigator.onLine === false) {
    return {
      message: "No Network. Please check your network connection",
      appearance: "error",
      autoDismiss: true
    }
  }
  return { message: "", appearance: "", autoDismiss: "" }
}

export default NoNetworkHandler
