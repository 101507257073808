import React, { useEffect, useState } from "react"

import { Modal } from "antd"
import { InputOTP } from "antd-input-otp"
import { useToasts } from "react-toast-notifications"

import { useDispatch, useSelector } from "react-redux"
import { registerOTP, resetRegisterOTPErr } from "../../redux/actions/patientAction"

const CODE_VALIDATION_MSG = "Code must be of 6 digits"
const CODE_SENT_SUCCESS_MSG = "Code created Successfully!"

const SetCodeModal = props => {
  const { selectedPatient, open, closeModal } = props
  const [otp, setOtp] = useState([])
  const [errors, setErrors] = useState("")
  const { loading, error: otpErr } = useSelector(state => state.registerOTP)
  const dispatch = useDispatch()

  const { addToast } = useToasts()

  useEffect(() => {
    if (open) {
      dispatch(resetRegisterOTPErr())
      setOtp([])
    }
  }, [open])

  const handleSubmit = async () => {
    setErrors("")
    if (!otp.length || otp.some(o => o === "") || otp.length < 6) {
      setErrors(CODE_VALIDATION_MSG)
    } else {
      setErrors("")
      const raw = { otp: otp.join("")?.toString() }
      dispatch(registerOTP(selectedPatient?.id, raw))
        .then(apiRes => {
          if (apiRes?.status === 200) {
            closeModal()
            addToast(CODE_SENT_SUCCESS_MSG, { appearance: "success", autoDismiss: true })
          } else {
            console.error("API Error:", apiRes?.error)
          }
        })
        .catch(error => {
          console.error("API Error:", error)
          addToast(error?.message, { appearance: "error", autoDismiss: true })
        })
    }
  }

  const DISPLAY_TITLE = "Enter 6 Digit Code"

  return (
    <>
      <Modal
        open={open}
        onOk={handleSubmit}
        confirmLoading={loading}
        onCancel={closeModal}
        okText="Generate Code"
        okType="text"
        okButtonProps={{ style: { backgroundColor: "#00a854", color: "#eee" } }}>
        <div className="mb-4">
          <p className="text-center capitalize my-3">{`${DISPLAY_TITLE} for ${selectedPatient?.name}`}</p>

          <InputOTP
            onChange={value => setOtp(value)}
            value={otp}
            inputType="numeric"
            length={6}
            inputClassName={`h-[40px] text-lg ${errors ? "border-danger" : ""}`}
          />
        </div>
        {errors && typeof errors === "string" ? (
          <p className="text-danger block text-center font-medium mt-2">{errors}</p>
        ) : null}

        {otpErr &&
          typeof otpErr === "object" &&
          Object.values(otpErr)?.map((value, index) => (
            <p key={index} className="font-bold text-danger text-center mt-4">
              <small>{value}</small>
            </p>
          ))}
      </Modal>
    </>
  )
}

export default SetCodeModal
