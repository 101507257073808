import { applyMiddleware, combineReducers, createStore } from "redux"
import { thunk } from "redux-thunk"
import { composeWithDevTools } from "@redux-devtools/extension"

import {
  userLoginReducer,
  passwordUpdateReducer,
  validateLoginDetailsReducer,
  updateProfileDetailsReducer,
  googleLoginReducer,
  postFeedbackReducer
} from "./reducers/userReducer"
import { getAllOrgReducer } from "./reducers/orgDetailsReducer"
import {
  editPatientReducer,
  getMarkPatientOnboardReducer,
  getPatientListReducer,
  registerOTPReducer,
  postBulkMailMessagePatientReducer,
  deniedEnrollmentReducer,
  postPatientEnrollManualReducer
} from "./reducers/patientReducer"
import { uploadFilesReducer } from "./reducers/uploadFileReducer"
import { addInternalNoteReducer, getInternalNotesReducer } from "./reducers/internalNotesReducer"

const reducer = combineReducers({
  // Internal Note
  addInternalNote: addInternalNoteReducer,
  getInternalNotes: getInternalNotesReducer,

  // Updload File
  uploadFiles: uploadFilesReducer,

  // User (Enrolment Specialist)
  userInfo: userLoginReducer,
  googleLogin: googleLoginReducer,
  passwordUpdate: passwordUpdateReducer,
  validateLoginDetails: validateLoginDetailsReducer,
  updateProfileDetails: updateProfileDetailsReducer,

  // Org details
  orgDetails: getAllOrgReducer,

  // Patient
  getPatientList: getPatientListReducer,
  editPatient: editPatientReducer,
  registerOTP: registerOTPReducer,
  postBulkMailMessagePatient: postBulkMailMessagePatientReducer,
  markPatientOnboard: getMarkPatientOnboardReducer,
  deniedEnrollment: deniedEnrollmentReducer,
  postPatientEnrollManual: postPatientEnrollManualReducer,
  // feedback
  postFeedback: postFeedbackReducer,
})

const middleware = [thunk]

const store = createStore(reducer, composeWithDevTools(applyMiddleware(...middleware)))
// const store = createStore(reducer, applyMiddleware(...middleware));

export default store
