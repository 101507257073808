import {
  GET_PATIENT_LIST_REQUEST,
  GET_PATIENT_LIST_SUCCESS,
  GET_PATIENT_LIST_FAIL,
  EDIT_PATIENT_REQUEST,
  EDIT_PATIENT_SUCCESS,
  EDIT_PATIENT_FAIL,
  EDIT_PATIENT_RESET,
  POST_REGISTER_OTP_RESET,
  POST_REGISTER_OTP_REQUEST,
  POST_REGISTER_OTP_SUCCESS,
  POST_REGISTER_OTP_FAIL,
  POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST,
  POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS,
  POST_BULK_MAIL_MESSAGE_PATIENT_FAIL,
  GET_MARK_PATIENT_ONBOARD_REQUEST,
  GET_MARK_PATIENT_ONBOARD_SUCCESS,
  GET_MARK_PATIENT_ONBOARD_FAIL,
  GET_MARK_PATIENT_ONBOARD_RESET,
  DENIED_ENROLLMENT_REQUEST,
  DENIED_ENROLLMENT_SUCCESS,
  DENIED_ENROLLMENT_FAIL,
  DENIED_ENROLLMENT_RESET,
  POST_PATIENT_MANUAL_ENROLL_RESET,
  POST_PATIENT_MANUAL_ENROLL_REQUEST,
  POST_PATIENT_MANUAL_ENROLL_SUCCESS,
  POST_PATIENT_MANUAL_ENROLL_FAIL
} from "../types/patientConstant"

export const getPatientListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PATIENT_LIST_REQUEST:
      return { loading: true }
    case GET_PATIENT_LIST_SUCCESS:
      return { loading: false, patientList: action.payload }
    case GET_PATIENT_LIST_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const editPatientReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_PATIENT_RESET:
      return {}
    case EDIT_PATIENT_REQUEST:
      return { loading: true }
    case EDIT_PATIENT_SUCCESS:
      return { loading: false, isUpdated: true }
    case EDIT_PATIENT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const registerOTPReducer = (
  state = {
    loading: false,
    status: []
  },
  action
) => {
  switch (action.type) {
    case POST_REGISTER_OTP_RESET:
      return { loading: false, state: [] }
    case POST_REGISTER_OTP_REQUEST:
      return { loading: true }
    case POST_REGISTER_OTP_SUCCESS:
      return { loading: false, status: action.payload }
    case POST_REGISTER_OTP_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const postBulkMailMessagePatientReducer = (
  state = { loading: false, error: null },
  action
) => {
  switch (action.type) {
    case POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST:
      return { loading: true }
    case POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS:
      return { loading: false, error: null }
    case POST_BULK_MAIL_MESSAGE_PATIENT_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

export const getMarkPatientOnboardReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MARK_PATIENT_ONBOARD_REQUEST:
      return { loading: true }
    case GET_MARK_PATIENT_ONBOARD_SUCCESS:
      return { loading: false, patientList: action.payload }
    case GET_MARK_PATIENT_ONBOARD_FAIL:
      return { loading: false, error: action.payload }
    case GET_MARK_PATIENT_ONBOARD_RESET:
      return { loading: false, error: null }
    default:
      return state
  }
}

export const deniedEnrollmentReducer = (state = {}, action) => {
  switch (action.type) {
    case DENIED_ENROLLMENT_REQUEST:
      return { loading: true }
    case DENIED_ENROLLMENT_SUCCESS:
      return { loading: false, deniedEnrollmentStatus: action.payload }
    case DENIED_ENROLLMENT_FAIL:
      return { loading: false, error: action.payload }
    case DENIED_ENROLLMENT_RESET:
      return { loading: false, error: null }
    default:
      return state
  }
}

export const postPatientEnrollManualReducer = (
  state = {
    loading: false,
    status: []
  },
  action
) => {
  switch (action.type) {
    case POST_PATIENT_MANUAL_ENROLL_RESET:
      return { loading: false, state: [] }
    case POST_PATIENT_MANUAL_ENROLL_REQUEST:
      return { loading: true }
    case POST_PATIENT_MANUAL_ENROLL_SUCCESS:
      return { loading: false, status: action.payload }
    case POST_PATIENT_MANUAL_ENROLL_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
