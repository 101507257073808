import React, { useEffect } from "react"
import { Button, Input, Modal } from "antd"
import { InputOTP } from "antd-input-otp"
import { useToasts } from "react-toast-notifications"
import { Controller, useForm } from "react-hook-form"
import TextArea from "antd/es/input/TextArea"
import { useDispatch, useSelector } from "react-redux"
import {
  getMarkPatientOnboardAction,
  getPatientListAction,
  resetMarkPatientOnboardOTPErr
} from "../../redux/actions/patientAction"
import { useParams } from "react-router-dom"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"

const CODE_SENT_SUCCESS_MSG = "Code submitted Successfully!"
const DISPLAY_TITLE = "Enter 6 Digit Onboarding Code"
const VALIDATION_MESSAGES = {
  OTP: {
    LENGTH: "Code must be of 6 digits",
    REQUIRED: "OTP is required"
  },
  NOTES: {
    REQUIRED: "Notes are required",
    MAX_LENGTH: "Notes cannot exceed 2000 characters"
  },
  ZENDESK_TICKET: {
    REQUIRED: "Zendesk ticket number is required",
    MAX_LENGTH: "Ticket Id cannot exceed 20 characters"
  }
}
// Define the validation schema with yup
const schema = yup.object().shape({
  otp: yup
    .string()
    .length(6, VALIDATION_MESSAGES.OTP.LENGTH)
    .required(VALIDATION_MESSAGES.OTP.REQUIRED),
  notes: yup
    .string()
    .required(VALIDATION_MESSAGES.NOTES.REQUIRED)
    .max(2000, VALIDATION_MESSAGES.NOTES.MAX_LENGTH),
  zendeskTicketId: yup
    .string()
    .required(VALIDATION_MESSAGES.ZENDESK_TICKET.REQUIRED)
    .max(20, VALIDATION_MESSAGES.ZENDESK_TICKET.MAX_LENGTH)
})

const OnboardPatientCodeModal = props => {
  const { selectedPatient, open, closeModal } = props
  const { loading, error: markPatientOnboardError } = useSelector(state => state.markPatientOnboard)
  const dispatch = useDispatch()
  const { orgId } = useParams()
  const { addToast } = useToasts()

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      otp: "",
      notes: "",
      zendeskTicketId: ""
    }
  })

  useEffect(() => {
    if (open) {
      dispatch(resetMarkPatientOnboardOTPErr())
      reset() // Reset form fields when modal opens
    }
  }, [open, dispatch, reset])

  const onSubmit = async data => {
    const formData = {
      enrolledOn: moment().format("YYYY-MM-DDTHH:mm:ss.SSS"),
      zendeskTicketId: data?.zendeskTicketId,
      notes: data?.notes
    }
    dispatch(getMarkPatientOnboardAction(selectedPatient?.id, data.otp, formData))
      .then(apiRes => {
        if (apiRes?.status === 200) {
          closeModal()
          dispatch(getPatientListAction(orgId))
          addToast(CODE_SENT_SUCCESS_MSG, { appearance: "success", autoDismiss: true })
        } else {
          console.error("API Error:", apiRes?.error)
        }
      })
      .catch(error => {
        console.error("API Error:", error)
        addToast(error?.message, { appearance: "error", autoDismiss: true })
      })
  }

  return (
    <>
      <Modal open={open} confirmLoading={loading} onCancel={closeModal} footer={null}>
        <div className="mb-4">
          <p className="text-lg mt-3 font-semibold text-center capitalize my-3">
            {`${DISPLAY_TITLE} for ${selectedPatient?.name}`} <span className="text-danger">*</span>
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-3 mt-2 flex flex-col justify-center items-center">
              <Controller
                name="otp"
                control={control}
                render={({ field }) => (
                  <InputOTP
                    {...field}
                    onChange={value => field.onChange(value.join(""))}
                    value={field.value.split("")} // Ensure the input is controlled
                    inputType="numeric"
                    length={6}
                    inputClassName={`h-[40px] text-lg ${errors.otp ? "border-danger" : ""}`}
                  />
                )}
              />
              {errors.otp ? (
                <small className="text-danger font-semibold mt-2">{errors.otp.message}</small>
              ) : null}
              <p className="text-lg mt-3 font-semibold text-center">
                Enter Notes<span className="text-danger">*</span>
              </p>
              <Controller
                name="notes"
                control={control}
                render={({ field }) => (
                  <TextArea
                    rows={4}
                    className="my-3 w-full"
                    id="input-notes"
                    status={errors.notes ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors.notes ? (
                <small className="text-danger font-semibold">{errors.notes.message}</small>
              ) : null}

              <p className="text-lg mt-3 font-semibold text-center">
                Enter Zendesk Ticket Number<span className="text-danger">*</span>
              </p>
              <Controller
                name="zendeskTicketId"
                control={control}
                render={({ field }) => (
                  <Input
                    className="my-3 w-full"
                    id="input-zendeskTicketId"
                    status={errors.zendeskTicketId ? "error" : undefined}
                    {...field}
                  />
                )}
              />
              {errors.zendeskTicketId ? (
                <small className="text-danger font-semibold">
                  {errors.zendeskTicketId.message}
                </small>
              ) : null}
              <Button
                className="w-[200px] my-3"
                size="large"
                type="primary"
                htmlType="submit"
                loading={loading}>
                Confirm
              </Button>
            </div>
          </form>
        </div>

        {markPatientOnboardError ? (
          <p className="font-bold text-danger text-center my-2">{markPatientOnboardError}</p>
        ) : null}
      </Modal>
    </>
  )
}

export default OnboardPatientCodeModal
