const moment = require("moment")

const calculateAge = dob => {
  // Parse the input date of birth string
  const dobDate = moment.parseZone(dob).format("MM/DD/YYYY")

  // Get the current date
  const today = moment()

  // Calculate the age
  const age = today.diff(dobDate, "years")

  return age
}

export default calculateAge
