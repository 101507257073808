import { Button, Modal } from "antd"
import { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Cropper from "react-easy-crop"
import getCroppedImg from "../../utils/cropImage"
import { useToasts } from "react-toast-notifications"
import { POST_UPLOAD_FILES_RESET } from "../../redux/types/uploadFileConstant"
import { uploadFileAction } from "../../redux/actions/uploadFileAction"
import { getUserProfileAction } from "../../redux/actions/userActions"

const ProfilePictureUpload = ({ setShowUploadModal, showUploadModal, setProfilePic }) => {
  const [image, setImage] = useState(undefined)
  const [existingImage, setExistingImage] = useState(undefined)
  const [isDragging, setIsDragging] = useState(false)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [cropMode, setCropMode] = useState(false)
  const [cropped, setCropped] = useState(false)
  const { addToast } = useToasts()

  const dispatch = useDispatch()
  const { userInfo } = useSelector(state => state.userInfo)
  const { loading: postLoading, error: postError } = useSelector(state => state.uploadFiles)

  const loadFile = event => {
    const file = event.target.files?.[0]
    if (file && (file.type === "image/jpeg" || file.type === "image/jpg")) {
      setImage(URL.createObjectURL(file))
      setCropMode(true)
      setCropped(false)
    } else {
      alert("Please select a JPG or JPEG file.")
    }
  }

  const handleDragOver = event => {
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => {
    setIsDragging(false)
  }

  const handleDrop = event => {
    event.preventDefault()
    setIsDragging(false)
    const file = event.dataTransfer.files?.[0]
    if (file && (file.type === "image/jpeg" || file.type === "image/jpg")) {
      setImage(URL.createObjectURL(file))
      setCropMode(true)
      setCropped(false)
    } else {
      alert("Unsupported file type. Please select a JPG or JPEG file.")
    }
  }

  const handleCropComplete = useCallback(async () => {
    if (image && croppedAreaPixels) {
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels)
        if (croppedImage) {
          setImage(croppedImage)
          setCropMode(false)
          setCropped(true)
        } else {
          alert("Failed to crop the image. Please try again.")
        }
      } catch (e) {
        console.error(e)
      }
    }
  }, [image, croppedAreaPixels])

  const handleCancel = () => {
    dispatch({ type: POST_UPLOAD_FILES_RESET })
    setImage(undefined)
    setCropMode(false)
    setCropped(false)
  }

  const fetchBlobFromFileUrl = async (imageUrl, orgId) => {
    try {
      const response = await fetch(imageUrl)
      const blob = await response.blob()
      // Generate current timestamp
      const timestamp = Date.now()

      // Extract file extension from blob type
      const extension = blob.type.split("/")[1]

      // Construct filename with timestamp, and extension
      const filename = `${timestamp}.${extension}`
      const file = new File([blob], filename, { type: blob.type })
      return file
    } catch (error) {
      console.error("Error fetching blob:", error)
      throw error // Rethrow the error to handle it in the caller function
    }
  }

  const handleSave = async e => {
    e.stopPropagation()
    if (image && userInfo?.id) {
      try {
        const file = await fetchBlobFromFileUrl(image, userInfo?.id)

        const isDone = await dispatch(
          uploadFileAction({ userId: userInfo?.id, fileList: [file], fileType: "profile" })
        )

        if (isDone?.isUploaded) {
          setImage(isDone?.details?.url)
          addToast("Profile picture updated successfully", {
            appearance: "success",
            autoDismiss: true
          })
          dispatch(getUserProfileAction({ id: userInfo?.id }))
          setProfilePic(isDone?.fileUrls)
          setCropMode(false)
          setCropped(false)
          setShowUploadModal(false)
        } else {
          console.log("error occurred", postError)
        }
      } catch (error) {
        console.error("Error handling save:", error)
      }
    }
  }

  const handleClose = () => {
    setShowUploadModal(false)
    dispatch({ type: POST_UPLOAD_FILES_RESET })
  }
  return (
    <>
      <Modal
        footer={null}
        open={showUploadModal}
        onCancel={handleClose}
        title={<h4 className="text-xl">Upload picture</h4>}>
        <div className="flex items-center justify-center pt-6 bg-gray-100">
          <div
            className={`text-center p-4 border-2 border-dashed ${
              isDragging ? "border-green-500" : "border-gray-300"
            } bg-green-100 rounded-lg transition-colors duration-300 max-w-lg w-full`}>
            {cropMode ? (
              <div className="relative w-full h-64">
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={(croppedArea, croppedAreaPixels) =>
                    setCroppedAreaPixels(croppedAreaPixels)
                  }
                />
                <div className="absolute bottom-4 left-0 right-0 flex justify-center space-x-2">
                  <Button
                    style={{ background: "#fff", borderColor: "#000" }}
                    className="px-4 rounded-lg shadow-md hover:bg-blue-600 transition-colors duration-300"
                    onClick={handleCropComplete}>
                    Crop
                  </Button>
                  <Button
                    style={{ background: "#fff", borderColor: "#000" }}
                    className="px-4 rounded-lg shadow-md hover:bg-blue-600 transition-colors duration-300"
                    onClick={handleCancel}>
                    Cancel
                  </Button>
                </div>
              </div>
            ) : (
              <label
                className="w-full h-32 flex flex-col items-center justify-center bg-white rounded-lg cursor-pointer hover:bg-gray-100 transition-colors duration-300"
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}>
                {(image ?? existingImage) ? (
                  <img
                    src={image ?? existingImage}
                    alt="Uploaded"
                    className="w-32 h-32 rounded-full object-cover mt-4"
                  />
                ) : (
                  <span className="text-gray-500 text-sm">
                    Upload or Drag & Drop your profile picture
                  </span>
                )}
                <input
                  type="file"
                  className="hidden"
                  accept="image/jpeg, image/jpg"
                  onChange={event => event.target.files && loadFile(event)}
                />
              </label>
            )}
            {!cropMode && (
              <div className="mt-4">
                {image ? (
                  <>
                    <Button
                      className="px-4 rounded-lg shadow-md transition-colors duration-300 mr-2"
                      onClick={() => document.querySelector("#upload-input")?.click()}>
                      Change
                    </Button>
                    {cropped && (
                      <Button
                        className="px-4 rounded-lg shadow-md transition-colors duration-300 mr-2"
                        onClick={handleSave}
                        loading={postLoading}>
                        Upload
                      </Button>
                    )}
                    <Button
                      className="px-4 rounded-lg shadow-md hover:bg-red-600 transition-colors duration-300"
                      onClick={handleCancel}
                      disabled={postLoading}>
                      Cancel
                    </Button>
                  </>
                ) : (
                  existingImage && (
                    <button
                      className="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md transition-colors duration-300"
                      onClick={() => document.querySelector("#upload-input")?.click()}>
                      Change
                    </button>
                  )
                )}
              </div>
            )}
          </div>
          <input
            type="file"
            id="upload-input"
            className="hidden"
            accept="image/jpeg, image/jpg"
            onChange={event => event.target.files && loadFile(event)}
          />
        </div>
        {postError ? (
          <div className="my-2">
            <p className="text-danger font-semibold">{postError}</p>
          </div>
        ) : null}
      </Modal>
    </>
  )
}

export default ProfilePictureUpload
