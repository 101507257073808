import React, { useEffect, useState } from "react"
import OrgBackNavigation from "../Organization/OrgBackNavigation"
import { ProTable } from "@ant-design/pro-components"
import { DownOutlined, SearchOutlined } from "@ant-design/icons"
import getFullName from "../../utils/getFullName"
import { Button, Checkbox, Input, Space, Tooltip, Typography, Dropdown } from "antd"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import { useDispatch, useSelector } from "react-redux"
import {
  deniedEnrollmentAction,
  getPatientListAction,
  resetdeniedEnrollmentErr
} from "../../redux/actions/patientAction"
import OnBoardingStageDropdown from "./OnBoardingStageDropdown"
import { ONBOARDING_STAGES } from "./constants"
import "./PatientList.css"
import { useParams } from "react-router-dom"
import moment from "moment"
import { TbMailForward } from "react-icons/tb"
import { USA_DATE_FORMAT } from "../../utils/usaDateFormat"
import setTrimmedString from "../../utils/trimValue"
import calculateAge from "../../utils/calculateAge"
import prettifyHeight from "../../utils/prettifyHeight"
import EditPatientEmailModal from "./EditPatientEmailModal"
import EditPatientPhoneNumModal from "./EditPatientPhoneNumModal"
import SetCodeModal from "./setCodeModal"
import SendInviteModal from "./SendInviteModal"
import OnboardPatientCodeModal from "./onboardPatientCodeModal"
import EnrollByCodeModal from "./EnrollByCodeModal"
import { BsThreeDots } from "react-icons/bs"
import InternalNotesModal from "./InternalNotes/InternalNotesModal"
import { useToasts } from "react-toast-notifications"

const getOnboardingStage = ({ deniedService, signupPending, signupInitiated, signup }) => {
  if (deniedService) return ONBOARDING_STAGES?.denied_service
  if (signup) return ONBOARDING_STAGES?.signed_up
  else if (signupPending) return ONBOARDING_STAGES?.sign_up_pending
  else if (signupInitiated) return ONBOARDING_STAGES?.sign_up_initiated
  else return "-"
}

const getFullAddress = address => {
  const fullAddress = []

  if (address?.street) fullAddress.push(address.street)
  if (address?.city) fullAddress.push(address.city)
  if (address?.state) fullAddress.push(address.state)
  if (address?.zipcode) fullAddress.push(address.zipcode)

  return fullAddress?.join(", ")
}

const manupulateData = data =>
  data?.map(pt => ({
    id: pt?.id,
    name: getFullName(pt.firstName, pt.middleName, pt.lastName),
    email: pt?.email,
    mobileNumber: formatPhoneNumber(pt?.mobileNumber),
    onBoardingStage: getOnboardingStage(pt),
    deniedService: pt?.deniedService,
    signupPending: pt?.signupPending,
    signupInitiated: pt?.signupInitiated,
    signup: pt?.signup,
    dob: moment.parseZone(pt?.dob).format(USA_DATE_FORMAT),
    age: calculateAge(pt?.dob),
    gender: pt?.gender,
    height: prettifyHeight(pt?.height),
    weight: pt?.weight ? `${pt.weight} lbs` : "-",
    enrolledBy: pt?.deniedService ? "-" : pt?.enrolledBy,
    declinedTo: pt?.deniedService ? pt?.enrolledBy : "-",
    devices: pt?.devices ? pt?.devices?.replaceAll("~", ", ")?.replaceAll("_", " ") : "-",
    address: pt?.typeOfPatient === "facility" ? pt?.facilityName : getFullAddress(pt?.address),
    city: pt?.address?.city,
    postalCode: pt?.address?.zipcode,
    primaryInsuranceNumber: pt?.primaryInsuranceNumber,
    primaryInsuranceProvider: pt?.primaryInsuranceProvider,
    secondaryInsuranceNumber: pt?.secondaryInsuranceNumber,
    secondaryInsuranceProvider: pt?.secondaryInsuranceProvider,
    diagnosticDetails: pt?.diagnosticDetails?.replaceAll("~", ", "),
    userType: pt?.typeOfPatient
  }))

const PatientList = () => {
  const [allPatients, setAllPatients] = useState([])
  const [filteredPatients, setFilteredPatients] = useState(null)
  const [searchQuery, setSearchQuery] = useState("")
  const [onboardingSelectedStage, setOnboardingSelectedStage] = useState()
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [openEditEmailModal, setOpenEditEmailModal] = useState(false)
  const [openEditPhoneNumModal, setOpenEditPhoneNumModal] = useState(false)
  const [openSetCodeModal, setOpenSetCodeModal] = useState(false)
  const [openEnrollByCodeModal, setOpenEnrollByCodeModal] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [openSendInviteModal, setOpenSendInviteModal] = useState(false)
  const [openOnboardPatientCodeModal, setOpenOnboardPatientCodeModal] = useState(false)
  const [openInternalNotesModal, setOpenInternalNotesModal] = useState(false)
  const [actionRow, setActionRow] = useState(null)

  const dispatch = useDispatch()

  const { addToast } = useToasts()

  const { orgId } = useParams()

  useEffect(() => {
    if (orgId) dispatch(getPatientListAction(orgId))
  }, [orgId])

  const { loading, patientList } = useSelector(state => state.getPatientList)

  const { deniedEnrollmentStatus, error: deniedEnrollmentErr } = useSelector(
    state => state.deniedEnrollment
  )

  useEffect(() => {
    if (patientList?.length) setAllPatients(manupulateData(patientList))
    else setAllPatients([])
  }, [patientList])

  useEffect(() => {
    setSelectedRowKeys([])
    let patients = [...allPatients]
    if (!patients?.length) {
      setFilteredPatients(null)
      return
    }
    if (searchQuery) {
      const trimmedQuery = searchQuery.trim().toLowerCase()
      patients = patients?.filter(
        pt =>
          pt?.name?.toLowerCase().includes(trimmedQuery) ||
          pt?.email?.toLowerCase().includes(trimmedQuery) ||
          (formatPhoneNumber(trimmedQuery)
            ? pt?.mobileNumber?.includes(formatPhoneNumber(trimmedQuery))
            : false) ||
          pt?.primaryInsuranceNumber?.toLowerCase().includes(trimmedQuery) ||
          pt?.primaryInsuranceProvider?.toLowerCase().includes(trimmedQuery) ||
          pt?.secondaryInsuranceNumber?.toLowerCase().includes(trimmedQuery) ||
          pt?.secondaryInsuranceProvider?.toLowerCase().includes(trimmedQuery) ||
          pt?.diagnosticDetails?.toLowerCase().includes(trimmedQuery) ||
          pt?.enrolledBy?.toLowerCase().includes(trimmedQuery) ||
          pt?.declinedTo?.toLowerCase().includes(trimmedQuery) ||
          pt?.devices?.toLowerCase().includes(trimmedQuery) ||
          pt?.address?.toLowerCase().includes(trimmedQuery) ||
          pt?.city?.toLowerCase().includes(trimmedQuery) ||
          pt?.postalCode?.toLowerCase().includes(trimmedQuery)
      )
    }
    if (onboardingSelectedStage) {
      patients = patients?.filter(
        patient =>
          patient?.onBoardingStage?.toLowerCase() === onboardingSelectedStage?.toLowerCase()
      )
    }
    setFilteredPatients(patients)
  }, [allPatients, searchQuery, onboardingSelectedStage])

  const handleDeniedEnrollment = async patientId => {
    await dispatch(deniedEnrollmentAction(patientId))
    dispatch(getPatientListAction(orgId))
  }

  useEffect(() => {
    if (!deniedEnrollmentStatus?.success) return
    addToast("Denied Enrollment Successful", { appearance: "success", autoDismiss: true })
    dispatch(resetdeniedEnrollmentErr())
  }, [deniedEnrollmentStatus])

  useEffect(() => {
    if (!deniedEnrollmentErr) return
    addToast(deniedEnrollmentErr, { appearance: "error", autoDismiss: true })
    dispatch(resetdeniedEnrollmentErr())
  }, [deniedEnrollmentErr])

  const tableMenuItems = record => {
    const items = []

    const changeEmailItem = {
      key: "changeEmail",
      label: "Change Email",
      onClick: () => {
        setSelectedPatient(record)
        setOpenEditEmailModal(true)
      }
    }

    const changePhoneNumItem = {
      key: "changePhoneNumber",
      label: "Change Phone Number",
      onClick: () => {
        setSelectedPatient(record)
        setOpenEditPhoneNumModal(true)
      }
    }

    const enrollByCode = {
      key: "enrollByCode",
      label: "Enroll to Program",
      onClick: () => {
        setSelectedPatient(record)
        setOpenEnrollByCodeModal(true)
      }
    }

    const deniedEnrollmentItem = {
      key: "deniedEnrollment",
      label: "Denied Enrollment",
      onClick: () => {
        handleDeniedEnrollment(record?.id)
      }
    }

    const generateCodeItem = {
      key: "setCode",
      label: "Generate Code",
      onClick: () => {
        setSelectedPatient(record)
        setOpenSetCodeModal(record)
      }
    }

    const enrolledByMeItem = {
      key: "enrolledByMe",
      label: "Enrolled by me",
      onClick: () => {
        setSelectedPatient(record)
        setOpenOnboardPatientCodeModal(true)
      }
    }

    const internalNotesItem = {
      key: "internalNotes",
      label: "Internal Notes",
      onClick: () => {
        setSelectedPatient(record)
        setOpenInternalNotesModal(true)
      }
    }

    if (record?.signupPending) {
      items.push(changeEmailItem, changePhoneNumItem, enrollByCode)
    } else if (record?.signupInitiated) {
      items.push(generateCodeItem, enrollByCode)
    } else if (record?.signup) {
      items.push(enrolledByMeItem, generateCodeItem)
    }

    if (!record?.deniedService && !record?.signupInitiated && !record?.signup) {
      items.push(deniedEnrollmentItem)
    }

    items.push(internalNotesItem)
    return items
  }

  const handleSelectAll = selected => {
    setSelectedRowKeys(selected ? filteredPatients?.map(r => r.id) : [])
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    columnTitle: (
      <Checkbox
        checked={
          patientList?.length
            ? selectedRowKeys?.length ===
              (filteredPatients === null ? allPatients?.length : filteredPatients?.length)
            : false
        }
        indeterminate={
          selectedRowKeys?.length > 0 &&
          selectedRowKeys.length <
            (filteredPatients === null ? allPatients?.length : filteredPatients?.length)
        }
        onChange={e => handleSelectAll(e.target.checked)}
      />
    )
  }

  const columns = [
    {
      disable: true,
      title: "Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: 250,
      render: (name, record) => (
        <div className="flex justify-between items-center capitalize wrap-text">
          <span>{name?.toLowerCase()}</span>
          <span className="h-[32px]">
            <Dropdown
              menu={{
                items: tableMenuItems(record)
              }}
              onOpenChange={isOpen => {
                if (isOpen) setActionRow(`row-${record?.id}`)
                else setActionRow(null)
              }}
              trigger={["click"]}>
              <Button
                type="text"
                key="actionGroup"
                className={actionRow === `row-${record?.id}` ? "show-three-dot" : "hide-three-dot"}>
                <BsThreeDots />
              </Button>
            </Dropdown>
          </span>
        </div>
      )
    },
    { disable: true, title: "Phone", dataIndex: "mobileNumber", copyable: true },
    { disable: true, title: "Email", dataIndex: "email", copyable: true },
    {
      disable: true,
      title: "Onboarding Stage",
      dataIndex: "onBoardingStage",
      sorter: (a, b) => a.onBoardingStage.localeCompare(b.onBoardingStage)
    },
    { title: "Gender", dataIndex: "gender" },
    {
      title: "DOB",
      dataIndex: "dob",
      sorter: (a, b) => new Date(a.dob).getTime() > new Date(b.dob).getTime()
    },
    {
      title: "Age",
      dataIndex: "age"
    },
    { title: "Height", dataIndex: "height" },
    { title: "Weight", dataIndex: "weight" },
    {
      title: "Enrolled By",
      dataIndex: "enrolledBy",
      render: text => <span className="capitalize">{text}</span>
    },
    {
      title: "Declined To",
      dataIndex: "declinedTo",
      render: text => <span className="capitalize">{text}</span>
    },
    {
      title: "Device(s)",
      dataIndex: "devices",
      render: devices => <span className="capitalize">{devices}</span>
    },
    { title: "Address", dataIndex: "address" },
    { title: "City", dataIndex: "city" },
    { title: "Zip Code", dataIndex: "postalCode" },
    { title: "Primary Insurance", dataIndex: "primaryInsuranceNumber" },
    { title: "Primary Insurance Provider", dataIndex: "primaryInsuranceProvider" },
    { title: "Secondary Insurance", dataIndex: "secondaryInsuranceNumber" },
    { title: "Secondary Insurance Provider", dataIndex: "secondaryInsuranceProvider" },
    { title: "Diagnostic Details", dataIndex: "diagnosticDetails" },
    {
      title: "Patient Type",
      dataIndex: "userType",
      key: "userType ",
      sorter: (a, b) => a.userType.localeCompare(b.userType)
    }
  ]

  return (
    <>
      <OrgBackNavigation />

      <ProTable
        className="onboarded-patients-table"
        onRow={() => ({
          className: "tableRow"
        })}
        headerTitle={<Typography.Title level={3}>Eligible Patients</Typography.Title>}
        rowKey={record => record.id}
        columnsState={{
          persistenceKey: "pro-table-onboarded-patient-columns",
          persistenceType: "localStorage"
        }}
        dataSource={filteredPatients === null ? allPatients : filteredPatients}
        columns={columns}
        toolbar={{
          actions: [
            <Input
              key="search"
              style={{ width: 241, height: 48 }}
              value={searchQuery}
              onChange={e => setSearchQuery(setTrimmedString(e.target.value))}
              allowClear
              disabled={!patientList?.length}
              placeholder="Search Patient"
              prefix={<SearchOutlined className="disable" />}
            />
          ]
        }}
        tableAlertOptionRender={({ onCleanSelected }) => (
          <div className="flex gap-4 items-center">
            <Tooltip title="Send Invite">
              <Button
                type="primary"
                size="large"
                onClick={() => setOpenSendInviteModal(true)}
                shape="circle"
                icon={<TbMailForward size="24px" />}
              />
            </Tooltip>
            <Button onClick={onCleanSelected} className="mr-auto text-danger border-danger">
              Clear selection
            </Button>
          </div>
        )}
        rowSelection={rowSelection}
        loading={loading}
        options={{
          density: false,
          reload: false,
          fullScreen: false,
          setting: {
            children: (
              <Space.Compact
                className="card light flex flex-col xl:flex-row mb-[105px] xl:mb-0 gap-y-[8px]"
                style={{ borderWidth: 0, height: 48 }}>
                <Button
                  style={{ width: 241, height: "inherit" }}
                  className="flex place-items-center p-0">
                  <span className="secondary text-xs px-4">Filter By</span>
                  <div
                    className="border-l flex-1 flex items-center justify-center gap-2"
                    style={{
                      height: "inherit",
                      borderColor: "rgba(0, 0, 0, 0.1)"
                    }}>
                    Column Visibility
                    <DownOutlined />
                  </div>
                </Button>
                <OnBoardingStageDropdown
                  disabled={loading || !patientList?.length}
                  onboardingSelectedStage={onboardingSelectedStage}
                  setOnboardingSelectedStage={setOnboardingSelectedStage}
                />
              </Space.Compact>
            )
          }
        }}
        search={false}
        pagination={{
          defaultPageSize: 10,
          showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
        }}
        scroll={{ x: "max-content" }}
      />
      <EditPatientEmailModal
        open={openEditEmailModal}
        closeModal={() => {
          setOpenEditEmailModal(false)
          setSelectedPatient(null)
        }}
        selectedPatient={selectedPatient}
      />
      <EditPatientPhoneNumModal
        open={openEditPhoneNumModal}
        closeModal={() => {
          setOpenEditPhoneNumModal(false)
          setSelectedPatient(null)
        }}
        selectedPatient={selectedPatient}
      />
      <SetCodeModal
        open={openSetCodeModal}
        closeModal={() => {
          setOpenSetCodeModal(false)
          setSelectedPatient(null)
        }}
        selectedPatient={selectedPatient}
      />
      <SendInviteModal
        selectedPatientIds={selectedRowKeys}
        open={openSendInviteModal}
        onClose={() => setOpenSendInviteModal(false)}
      />
      <OnboardPatientCodeModal
        open={openOnboardPatientCodeModal}
        closeModal={() => {
          setOpenOnboardPatientCodeModal(false)
          setSelectedPatient(null)
        }}
        selectedPatient={selectedPatient}
      />
      <InternalNotesModal
        open={openInternalNotesModal}
        closeModal={() => {
          setOpenInternalNotesModal(false)
          setSelectedPatient(null)
        }}
        selectedPatient={selectedPatient}
      />
      <EnrollByCodeModal
        open={openEnrollByCodeModal}
        setOpenEnrollByCodeModal={setOpenEnrollByCodeModal}
        setSelectedPatient={setSelectedPatient}
        onClose={() => {
          setOpenEnrollByCodeModal(false)
          setSelectedPatient(null)
        }}
        selectedPatient={selectedPatient}
      />
    </>
  )
}

export default PatientList
