import React, { useEffect } from "react"
import { Modal, Tabs } from "antd"
import AddInternalNote from "./AddInternalNote"
import "./InternalNotes.css"
import InternalNotesList from "./InternalNotesList"
import { useDispatch, useSelector } from "react-redux"
import { getInternalNotesAction } from "../../../redux/actions/internalNotesAction"

const InternalNotesModal = props => {
  const { selectedPatient, open, closeModal } = props

  const { loading: getNoteLoading, internalNotes } = useSelector(state => state.getInternalNotes)

  const dispatch = useDispatch()

  useEffect(() => {
    if (selectedPatient?.id) dispatch(getInternalNotesAction({ patientId: selectedPatient?.id }))
  }, [selectedPatient])

  const tabItems = [
    {
      key: "addInternalNote",
      label: "Add internal note",
      children: <AddInternalNote closeModal={closeModal} patientId={selectedPatient?.id} />
    },
    {
      key: "viewInternalNote",
      label: "View internal note",
      children: <InternalNotesList />
    }
  ]

  return (
    <Modal
      title="Internal Note"
      centered
      width="50%"
      zIndex={999999}
      destroyOnClose
      open={open}
      onCancel={closeModal}
      footer={null}>
      {!getNoteLoading && internalNotes?.length ? (
        <Tabs defaultActiveKey={tabItems?.[0]?.key} items={tabItems} />
      ) : (
        <AddInternalNote closeModal={closeModal} patientId={selectedPatient?.id} />
      )}
    </Modal>
  )
}

export default InternalNotesModal
