import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"

import { Input, Typography } from "antd"

import { useSelector, useDispatch } from "react-redux"

import { ProTable } from "@ant-design/pro-components"
import { CloseCircleOutlined, DownOutlined, SearchOutlined } from "@ant-design/icons"

import "./style.css"
import { getAllOrgAction } from "../../redux/actions/orgDetailsAction.js"
import formatPhoneNumber from "../../utils/formatPhoneNumber"
import setTrimmedString from "../../utils/trimValue.js"

const OrganizationList = () => {
  const navigate = useNavigate()

  const [tableData, setTableData] = useState([])
  const [searchInp, setSearchInp] = useState("")

  const dispatch = useDispatch()
  const { orgData, loading } = useSelector(state => state.orgDetails)

  const proColumns = [
    {
      disable: true,
      title: "NAME",
      dataIndex: "organizationName",
      sorter: (a, b) => a.organizationName.localeCompare(b.organizationName),
      render: organizationName => <p className="capitalize">{organizationName}</p>
    },
    {
      title: "EMAIL",
      dataIndex: "email"
    },
    {
      disable: true,
      title: "PHONE NUMBER",
      dataIndex: "mobileNumber",
      sorter: (a, b) => Number(a.mobileNumber) - Number(b.mobileNumber),
      renderText: text => formatPhoneNumber(text)
    },
    {
      title: "ORGANIZATION ID",
      dataIndex: "orgId"
    },
    {
      // key: "more",
      title: "ACTION",
      render: (_, { orgId, organizationName }) => (
        <Typography.Link
          onClick={() => {
            navigate(`${orgId}/${organizationName}/patient`)
          }}>
          View More
        </Typography.Link>
      )
    }
  ]

  useEffect(() => {
    dispatch(getAllOrgAction())
  }, [])

  useEffect(() => {
    if (orgData?.length) {
      const data = []
      orgData?.forEach((opt, index) =>
        data.push({
          key: index,
          organizationName: opt?.organizationName,
          orgId: opt?.id,
          mobileNumber: opt?.mobileNumber,
          email: opt?.email
        })
      )

      setTableData(data)
    }
  }, [orgData])

  const onSearchOrg = e => {
    const query = setTrimmedString(e?.target?.value)

    setSearchInp(query)

    const data = []

    let filterData = []
    if (query) {
      const trimmedSearchValue = query?.trim()?.toLowerCase()
      filterData = orgData?.filter(
        ({ id, organizationName, mobileNumber, email }) =>
          id?.toLowerCase()?.includes(trimmedSearchValue) ||
          organizationName?.toLowerCase()?.includes(trimmedSearchValue) ||
          mobileNumber?.includes(trimmedSearchValue) ||
          formatPhoneNumber(mobileNumber)?.includes(trimmedSearchValue) ||
          email?.toLowerCase()?.includes(trimmedSearchValue)
      )
    } else filterData = orgData

    filterData?.forEach((opt, index) =>
      data.push({
        key: index,
        organizationName: opt?.organizationName,
        orgId: opt?.id,
        mobileNumber: opt?.mobileNumber,
        email: opt?.email
      })
    )

    setTableData(data)
  }

  return (
    <div>
      <div className="flex justify-between flex-col sm:flex-row">
        <Typography.Title level={3}>Organization</Typography.Title>
      </div>

      <ProTable
        id="orgListProTable"
        className="orgTable"
        columns={proColumns}
        dataSource={tableData}
        loading={loading}
        columnsState={{
          persistenceKey: "pro-table-org-list-columns",
          persistenceType: "localStorage"
        }}
        rowKey="key"
        toolbar={{
          actions: [
            <Input
              key="search"
              style={{ width: 241, height: 48 }}
              value={searchInp}
              onChange={onSearchOrg}
              allowClear={{
                clearIcon: <CloseCircleOutlined onClick={() => setSearchInp("")} />
              }}
              disabled={!orgData?.length}
              placeholder="Search Organization"
              prefix={<SearchOutlined className="disable" />}
            />
          ]
        }}
        options={{
          density: false,
          reload: false,
          fullScreen: false,
          setting: {
            children: (
              <div
                className="card light"
                style={{
                  width: 241,
                  height: 48,
                  display: "flex",
                  placeItems: "center",
                  flexDirection: "row"
                }}>
                <span className="disable text-xs px-3">Filter By</span>
                <div
                  className="primary border-l-2 h-full text-center flex-1"
                  style={{
                    display: "flex",
                    placeItems: "center",
                    borderColor: "rgba(0, 0, 0, 0.1)"
                  }}>
                  <div className="flex-1">
                    <span className="px-2">Column Visibility</span>
                    <DownOutlined />
                  </div>
                </div>
              </div>
            )
          }
        }}
        search={false}
        // dateFormatter="string"
        pagination={{
          defaultPageSize: 10,
          showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
        }}
        style={{ paddingTop: "30px" }}
        scroll={{ x: "max-content" }}
      />
    </div>
  )
}

export default OrganizationList
