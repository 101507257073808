import { axiosGet, axiosPost } from "../axios"
import {
  GET_PATIENT_LIST_REQUEST,
  GET_PATIENT_LIST_SUCCESS,
  GET_PATIENT_LIST_FAIL,
  EDIT_PATIENT_REQUEST,
  EDIT_PATIENT_SUCCESS,
  EDIT_PATIENT_FAIL,
  EDIT_PATIENT_RESET,
  POST_REGISTER_OTP_REQUEST,
  POST_REGISTER_OTP_SUCCESS,
  POST_REGISTER_OTP_FAIL,
  POST_REGISTER_OTP_RESET,
  POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST,
  POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS,
  POST_BULK_MAIL_MESSAGE_PATIENT_FAIL,
  GET_MARK_PATIENT_ONBOARD_REQUEST,
  GET_MARK_PATIENT_ONBOARD_SUCCESS,
  GET_MARK_PATIENT_ONBOARD_FAIL,
  GET_MARK_PATIENT_ONBOARD_RESET,
  DENIED_ENROLLMENT_REQUEST,
  DENIED_ENROLLMENT_SUCCESS,
  DENIED_ENROLLMENT_FAIL,
  DENIED_ENROLLMENT_RESET,
  POST_PATIENT_MANUAL_ENROLL_REQUEST,
  POST_PATIENT_MANUAL_ENROLL_SUCCESS,
  POST_PATIENT_MANUAL_ENROLL_FAIL
} from "../types/patientConstant"
import { userLogoutAction } from "./userActions"
import store from "../store"

export const getPatientListAction = orgId => async dispatch => {
  try {
    dispatch({
      type: GET_PATIENT_LIST_REQUEST
    })

    const { data } = await axiosGet(
      `/api/cs/bulk-upload/onboardingStatus/enrollment/profiles/${orgId}/fetch`
    )

    dispatch({
      type: GET_PATIENT_LIST_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PATIENT_LIST_FAIL,
      payload:
        typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const editPatientAction =
  ({ patientId, email, mobileNumber }) =>
  async dispatch => {
    try {
      dispatch({
        type: EDIT_PATIENT_REQUEST
      })

      const { data } = await axiosPost(
        `/api/cs/bulk-upload/onboardingStatus/enrollment/profiles/${patientId}/update`,
        { email, mobileNumber }
      )

      dispatch({
        type: EDIT_PATIENT_SUCCESS
      })

      // modify patient detail in patient list also
      const existingPatients = store?.getState()?.getPatientList?.patientList
      const index = existingPatients?.findIndex(item => item?.id === patientId)

      const updatedPatientList =
        index !== -1
          ? [
              ...existingPatients.slice(0, index),
              { ...existingPatients[index], email, mobileNumber },
              ...existingPatients.slice(index + 1)
            ]
          : existingPatients

      dispatch({
        type: GET_PATIENT_LIST_SUCCESS,
        payload: updatedPatientList
      })

      return { data, isUpdated: true }
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: EDIT_PATIENT_FAIL,
        payload:
          typeof error?.response?.data?.details === "object" &&
          Object.values(error?.response?.data?.details)?.[0]
      })
    }
  }

export const resetEditPatientAction = () => async dispatch => {
  dispatch({
    type: EDIT_PATIENT_RESET
  })
}

export const registerOTP = (patientID, code) => async dispatch => {
  try {
    dispatch({
      type: POST_REGISTER_OTP_REQUEST
    })
    const { data } = await axiosPost(`/api/cs/patients/opt/${patientID}/create`, code)

    dispatch({
      type: POST_REGISTER_OTP_SUCCESS,
      payload: data?.details
    })
    return data
  } catch (error) {
    dispatch({
      type: POST_REGISTER_OTP_FAIL,
      payload: error.response?.data?.details
    })
  }
}

export const resetRegisterOTPErr = () => async dispatch => {
  dispatch({
    type: POST_REGISTER_OTP_RESET
  })
}

// POST action for bulk mail/messages to the patients
export const postBulkMailMessagePatientAction = formData => async dispatch => {
  try {
    dispatch({ type: POST_BULK_MAIL_MESSAGE_PATIENT_REQUEST })

    const { data } = await axiosPost(`/api/cs/bulk-upload/messages`, formData)
    dispatch({
      type: POST_BULK_MAIL_MESSAGE_PATIENT_SUCCESS,
      payload: data?.details
    })
    return { success: true, data: data?.details }
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: POST_BULK_MAIL_MESSAGE_PATIENT_FAIL,
      payload:
        Object.values(error?.response?.data?.details)[0] ?? error?.response?.data?.details?.message
    })
    return {
      success: false,
      error:
        Object.values(error?.response?.data?.details)[0] ?? error?.response?.data?.details?.message
    }
  }
}
// version 2 of the core service
export const getMarkPatientOnboardAction = (patientId, code, formData) => async dispatch => {
  try {
    dispatch({
      type: GET_MARK_PATIENT_ONBOARD_REQUEST
    })

    const { data } = await axiosPost(
      `/api/cs2/bulk-upload/onboardingStatus/enrollment/profile/${patientId}/code/${code}`,
      formData
    )

    dispatch({
      type: GET_MARK_PATIENT_ONBOARD_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_MARK_PATIENT_ONBOARD_FAIL,
      payload:
        typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const resetMarkPatientOnboardOTPErr = () => async dispatch => {
  dispatch({
    type: GET_MARK_PATIENT_ONBOARD_RESET
  })
}

// POST action for the patient enroll manually
export const postPatientEnrollManualAction =
  ({ patientId, formData }) =>
  async dispatch => {
    try {
      dispatch({
        type: POST_PATIENT_MANUAL_ENROLL_REQUEST
      })
      const { data } = await axiosPost(`/api/cs/bulk-upload/enroll/${patientId}/create`, formData)

      dispatch({
        type: POST_PATIENT_MANUAL_ENROLL_SUCCESS,
        payload: data?.details
      })
      return data
    } catch (error) {
      dispatch({
        type: POST_PATIENT_MANUAL_ENROLL_FAIL,
        payload:
          typeof error?.response?.data?.details === "object" &&
          Object.values(error?.response?.data?.details)?.[0]
      })
    }
  }

export const deniedEnrollmentAction = patientId => async dispatch => {
  try {
    dispatch({
      type: DENIED_ENROLLMENT_REQUEST
    })

    await axiosGet(`/api/cs/bulk-upload/deniedService/${patientId}/update`)

    const data = { success: true }
    dispatch({
      type: DENIED_ENROLLMENT_SUCCESS,
      payload: data
    })
    return data
  } catch (error) {
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: DENIED_ENROLLMENT_FAIL,
      payload:
        typeof error?.response?.data?.details === "object" &&
        Object.values(error?.response?.data?.details)?.[0]
    })
  }
}

export const resetdeniedEnrollmentErr = () => async dispatch => {
  dispatch({
    type: DENIED_ENROLLMENT_RESET
  })
}
