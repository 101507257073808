import { Button } from "antd"
import { useNavigate } from "react-router-dom"
import notFoundImg from "../assets/404.png"

const Page404 = () => {
  const navigate = useNavigate()

  return (
    <div className="flex items-center justify-center flex-col h-screen p-4">
      <h1 className="mb-4 text-6xl text-current">Page not found</h1>
      <img alt="404" width={600} className="img-fluid" src={notFoundImg} />

      <div className="d-flex justify-content-center align-items-center">
        <Button type="primary" className="px-5 btn-sm shadow" onClick={() => navigate("/")}>
          Back to Home
        </Button>
      </div>
    </div>
  )
}

export default Page404
